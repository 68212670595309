import React from 'react'
import { NavLink, Flex, Container, Box, Grid, Text, Divider } from '@theme-ui/components'
import { StaticImage } from 'gatsby-plugin-image'

import { Link as GatsbyLink } from 'gatsby'
import { Link } from '@theme-ui/components'

const footer = () => {
  return (
    <>
    <Box p={0} as="footer" sx={{
      minWidth: '100%',
      backgroundColor: 'muted',
      borderTop: '1px solid',
      borderColor: 'secondary',
      position: 'relative',
      bottom: 0,
      paddingBottom: '80px',
      minHeight: '100px',
    }}>
      <Container>
      <Grid gap={4} columns={[1, 1, 3]}>
        <Box py={2}>
        <Text as="h4" sx={{
          color: 'primary',
        }}>
          AME Systems Mission
        </Text>
        <Divider sx={{
          backgroundColor: 'primary',
        }}/>
        <Text as="p" sx={{
          color: 'text',
          fontSize: '12px',
        }}>
        To empower asset stakeholders and their guardians, with quality, accessible, affordable training.
        </Text>
        </Box>
        <Box py={2}>
        <Text as="h4" sx={{
          color: 'primary',
        }}>
          Course Contributors
        </Text>
        <Divider sx={{
          backgroundColor: 'primary',
        }}/>
        <Text as="p" sx={{
          color: 'text',
          fontSize: '12px',
        }}>
          <Link p={2} sx={{ color: 'primary' }} href='https://sdtultrasound.com' as={GatsbyLink}>SDT Ultrasound Solutions</Link>
          <br />
          <Link p={2} sx={{ color: 'primary' }} href='https://consciousreliability.com' as={GatsbyLink}>Conscious Reliability</Link>
        </Text>
        </Box>
        <Box py={2}>
        <Text as="h4" sx={{
          color: 'primary',
        }}>
          Contact Us
        </Text>
        <Divider sx={{
          backgroundColor: 'primary',
        }}/>
        <Text as="p" sx={{
          color: 'text',
          fontSize: '12px',
        }}>
          905-377-1313
        </Text>
        </Box>
      </Grid>
      <Box py={2}>
       <Text as="p" sx={{
         fontSize: '12px',
         color: 'text',
         opacity: 0.8,
       }}>Copyright © {new Date().getFullYear()} AME Systems</Text>
      </Box>
        
      </Container>
    </Box>
    
    </>
  )
}

export default footer
